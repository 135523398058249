import styledialLogo from "../../assets/styledial.svg";
import { useNavigate } from "react-router-dom";
import "./LandingPage.style.scss";

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <div
      className="landing-page-background flex flex-col min-h-screen justify-evenly relative"
      style={{
        backgroundColor: "black",
      }}
    >
      <div
        className={
          "navigate-button flex flex-row justify-end text-white text-xl px-6 gap-3"
        }
      >
        <span
          className="hover:cursor-pointer"
          onClick={() => navigate("/terms-of-use")}
        >
          Term of Use
        </span>

        <span
          className="hover:cursor-pointer"
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </span>
      </div>
      <div className="flex flex-col flex-wrap gap-20 w-full h-full items-center justify-center px-2 min-[375px]:px-6">
        <img className="w-fit" src={styledialLogo} alt="logo" />

        <div className="flex flex-col gap-5 text-center text-white text-2xl min-[375px]:text-[34px] font-medium leading-[34px]">
          <span>The world's first</span>

          <span>AI styling coach for men going on dates</span>
        </div>
      </div>
      <div className="flex flex-col gap-4 text-2xl text-white text-center pb-16">
        <span>© 2024 styledial</span>
      </div>
    </div>
  );
};

export default LandingPage;
