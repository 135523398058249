import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import "./Header.scss";

function HeaderInApp() {
  const { pathname } = useLocation();

  return (
    <Box
      className="terms-and-policy__header"
      as="header"
      height={"402px"}
      pt={"50px"}
    >
      <Box
        className="header__page-title"
        as="h1"
        color={"#FFF"}
        fontSize={"64px"}
        fontWeight={"400"}
        lineHeight={"normal"}
        letterSpacing={"-0.32px"}
        mt={"81px"}
        textAlign={"center"}
        width={"1600px"}
        mx={"auto"}
      >
        {pathname === "/terms-of-use/app" ? "Terms of Use" : "Privacy Policy"}
      </Box>
    </Box>
  );
}

export default HeaderInApp;
