import { ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react";

const PrivacyPolicyContent = {
  "1.	Our Privacy Philosophy": (
    <Text>
      {
        'STYLEDIAL Inc. ("STYLEDIAL," "we," or "us") values your trust and is committed to protecting your privacy. We prioritize accuracy, confidentiality, and security in handling your personal information. This Privacy Policy explains how we collect, use, and share your information, ensuring transparency and compliance with applicable privacy laws and regulations'
      }
    </Text>
  ),
  "2. Our Policy": (
    <Stack spacing={"20px"}>
      <Text>
        This Privacy Policy applies to all services provided by STYLEDIAL,
        including websites, mobile applications, and interactions with our
        services. “Personal information” refers to any data that can identify an
        individual directly or indirectly. This Policy excludes information
        solely used for business-to-business communication.
      </Text>
    </Stack>
  ),
  "3.	Agreeing to the Terms of Our Privacy Policy": (
    <Stack spacing={"20px"}>
      <Text>
        By providing personal information, using our website, or interacting
        with our services, you consent to our data practices as described in
        this Policy. You have the right to withdraw your consent at any time,
        though this may limit your access to our services. For instructions on
        withdrawing consent, see Section 10.
      </Text>
    </Stack>
  ),
  "4.	Types of Information Collected": (
    <Stack spacing={"20px"}>
      <Text>We collect the following types of information:</Text>
      <UnorderedList className="policy-list flex flex-col gap-4" pl={"30px"}>
        <ListItem>
          <span className="font-semibold">Contact information:</span> Name,
          email, phone number, and address.
        </ListItem>

        <ListItem>
          <span className="font-semibold">Payment information:</span> Bank
          account details and billing information.
        </ListItem>

        <ListItem>
          <span className="font-semibold">Profile information:</span> Username,
          preferences, feedback, and survey responses.
        </ListItem>

        <ListItem>
          <span className="font-semibold">Chat Data:</span> Conversations with
          our AI bot or human representatives.
        </ListItem>

        <ListItem>
          <span className="font-semibold">Health Information:</span>Limited
          health-related data (e.g., menstrual dates and durations) provided
          voluntarily.
        </ListItem>

        <ListItem>
          <span className="font-semibold">Device Data:</span> IP address, device
          identifiers, location, browser type, and usage behavior.
        </ListItem>

        <ListItem>
          <span className="font-semibold pr-1">Marketing Preferences:</span>
          Communication preferences and marketing opt-ins.
        </ListItem>

        <ListItem>
          <span className="font-semibold">Other Voluntary Information: </span>:
          Any additional information shared during interactions with our
          services.
        </ListItem>
      </UnorderedList>
    </Stack>
  ),
  "5.	Why We Collect Your Information": (
    <Stack spacing={"20px"}>
      <Text>We collect information to:</Text>
      <UnorderedList className="policy-list flex flex-col gap-4" pl={"30px"}>
        <ListItem>Personalize recommendations and services.</ListItem>

        <ListItem>
          Facilitate order fulfillment and customer interactions.
        </ListItem>

        <ListItem>
          Improve user experience, troubleshoot, and enhance security.
        </ListItem>

        <ListItem>
          Conduct marketing, surveys, and promotional activities.
        </ListItem>

        <ListItem>
          Comply with legal obligations and regulatory requirements.
        </ListItem>

        <ListItem>Conduct business development and analytics.</ListItem>
      </UnorderedList>
    </Stack>
  ),
  "6.	How We Collect Your Information": (
    <Stack spacing={"20px"}>
      <Text>We collect personal information through:</Text>
      <UnorderedList className="policy-list flex flex-col gap-4" pl={"30px"}>
        <ListItem>
          <span className="font-semibold">Direct Interactions: </span>When you
          provide data via forms, apps, or calls.
        </ListItem>
        <ListItem>
          <span className="font-semibold">Automated Technologies: </span>Cookies
          and tracking tools.
        </ListItem>
        <ListItem>
          <span className="font-semibold">Third Parties:</span>
          Authorized data-sharing partners.
        </ListItem>
      </UnorderedList>
      <Text className="flex flex-col gap-3">
        <span className="font-semibold italic">a) Cookies</span>
        <span>
          We use cookies to enhance user experience, track website behavior, and
          deliver targeted advertisements. You can disable cookies via browser
          settings, though this may affect functionality.
        </span>
      </Text>
      <Text className="flex flex-col gap-3">
        <span className="font-semibold italic">b) Social media</span>
        <span>
          Information posted on public platforms (e.g., reviews or comments) may
          be accessible to others.
        </span>
      </Text>
    </Stack>
  ),
  "7.	Who We Share Your Information With": (
    <Stack spacing={"20px"}>
      <Text>We may share your personal information with:</Text>
      <UnorderedList className="policy-list flex flex-col gap-4" pl={"30px"}>
        <ListItem>
          <span className="font-semibold">Service Providers:</span> Cloud
          storage, IT support, and payment processors.
        </ListItem>

        <ListItem>
          <span className="font-semibold pr-1">Marketing Partners:</span>
          Companies managing campaigns or analytics.
        </ListItem>

        <ListItem>
          <span className="font-semibold pr-1">Law Enforcement:</span>
          As required by applicable law or to protect safety and rights.
        </ListItem>
      </UnorderedList>
      <Text>
        We do not share mobile information with third parties or affiliates.
        Data shared with third parties will comply with contractual and legal
        safeguards to protect your privacy.
      </Text>
    </Stack>
  ),
  "8.	Accuracy": (
    <Stack spacing={"20px"}>
      <Text>
        <Text>
          We strive to maintain accurate and up-to-date records. You may request
          updates or corrections by contacting us (see Section 15).
        </Text>
      </Text>
    </Stack>
  ),
  "9.	Protecting Your Personal Information": (
    <Stack spacing={"20px"}>
      <Text>
        We implement industry-standard safeguards, including encryption and
        access controls, to protect your data. While we strive to ensure
        security, no system is completely secure. We encourage users to remain
        vigilant and report suspicious activity.
      </Text>
    </Stack>
  ),
  "10.	Opting-out": (
    <Stack spacing={"20px"}>
      <Text>You may:</Text>
      <UnorderedList className="policy-list flex flex-col gap-4" pl={"30px"}>
        <ListItem>
          Withdraw consent for data collection and processing by contacting us.
        </ListItem>

        <ListItem>Opt out of cookies via browser settings.</ListItem>

        <ListItem>
          Opt out of targeted advertising via industry tools like the Digital
          Advertising Alliance.
        </ListItem>
      </UnorderedList>
    </Stack>
  ),
  "11. Updating this Privacy Policy": (
    <Text>
      This Privacy Policy is reviewed periodically to reflect changes in our
      services or regulatory requirements. Updates will be posted on our website
      with an effective date.
    </Text>
  ),
  "12. Website and Apps Governed by this Privacy Policy": (
    <Text>
      This Policy applies to STYLEDIAL’s websites and apps. It does not cover
      third-party platforms linked from our website. Review the privacy policies
      of third-party sites before sharing data.
    </Text>
  ),
  "13. Governing Law": (
    <Text>
      This Policy is governed by the laws of the State of Delaware, United
      States, and applicable federal laws.
    </Text>
  ),
  "14. Personal Information Outside of Canada": (
    <Text>
      Data may be stored or processed outside the United States, subject to
      local laws (e.g., Twilio’s global operations). We take steps to ensure
      compliance with privacy standards across jurisdictions. For California
      residents, we comply with the California Consumer Privacy Act (CCPA),
      which grants specific rights, including the right to access, delete, and
      opt out of the sale of personal information. For more information,
      California residents may contact us at the details provided in Section 15.
    </Text>
  ),
  "15. Addressing Your Inquiries and Concerns": (
    <Stack>
      <Text>
        For questions, corrections, or withdrawal of consent, contact us
      </Text>
      <UnorderedList className="policy-list flex flex-col gap-4" pl={"30px"}>
        <ListItem>
          <span className="font-semibold">Email: </span> info@styledial.com
        </ListItem>

        <ListItem>
          <span className="font-semibold pr-1">Mailing Address: </span>
          STYLEDIAL Inc., 1209 N Orange St, Wilmington, DE 19801, USA.
        </ListItem>

        <ListItem>
          <span className="font-semibold pr-1">Last Updated: </span>
          January 2, 2025
        </ListItem>
      </UnorderedList>
    </Stack>
  ),
};

export default PrivacyPolicyContent;
